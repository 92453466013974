import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('@/views/index.vue'),
    meta:{
      title:'云掌柜-住宿业数字化营销管家'
    }
  },
  {
    path: '/yzgInstructions',
    name: 'yzgInstructions',
    component: () => import('@/views/yzgInstructions.vue'),
    meta:{
      title:"为什么选择云掌柜"
    }
  },
  {
    path: '/college',
    name: 'college',
    component: () => import('@/views/college.vue'),
    meta:{
      title:"民宿学苑"
    }
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: () => import('@/views/aboutUs.vue'),
    meta:{
      title:"关于我们"
    }
  }
  ,
  {
    path: '/userDemo',
    name: 'userDemo',
    component: () => import('@/views/userDemo.vue'),
    meta:{
      title:"客户案例"
    },
  },

  {
    path: '/detailCase',
    name: 'detailCase',
    component: () => import ('@/views/detailCase.vue'),
    meta: {
      title: '客户案例'
    }
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
